@font-face {
  font-family: 'Ghetto Font';
  src: url('./font/Whoduck.ttf') format('truetype');
}


body {
  margin: 0;
  font-family: 'Ghetto Font', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;body {
  }
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
