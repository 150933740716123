.photo-section {
  display: flex;
  justify-content: center;
}

.photo-section img {
  max-width: 100%; /* Ajusta el ancho máximo de la imagen */
  align-items: center;
}

.image-container {
  display: flex;
  flex-direction: column; /* Organiza las imágenes en columna */
  align-items: center; /* Centra las imágenes horizontalmente */
}

.image-container img {
  max-width: 100%; /* Ajusta el ancho máximo de las imágenes */
  max-height: 100%; /* Ajusta la altura máxima de las imágenes */
  margin-bottom: 20px; /* Espacio entre las imágenes */
}

.inline-images {
  display: flex;
  justify-content: space-between; /* Distribuye las imágenes de manera uniforme */
  max-width: 100%; /* Ajusta el ancho máximo del contenedor */
  margin-bottom: 20px; /* Espacio entre las imágenes */
}

.inline-images img {
  max-width: calc(33% - 10px); /* Ajusta el ancho de cada imagen */
}

.photo-section img:hover {
  transform: translate(
    -2px,
    -2px
  ); /* Mueve la imagen hacia arriba y hacia la izquierda */
  animation: shake 0.1s infinite alternate; /* Agrega una animación de temblor */
}

@keyframes shake {
  from {
    transform: translate(
      -2px,
      -2px
    ); /* Mueve la imagen hacia arriba y hacia la izquierda */
  }
  to {
    transform: translate(
      2px,
      2px
    ); /* Mueve la imagen hacia abajo y hacia la derecha */
  }
}
